import React from "react";
import "../styles/header.css";

import Nav from "../components/nav.jsx";
import Logo from "../assets/images/logo_2.png";
import { Link } from "react-router-dom";
import LogoText from "../assets/images/logo_text.png";

export default function Header() {
  return (
    <header className="header">
      <div className="header-width">
        <div className="header-container">
          <div className="name">
          <img src={LogoText} alt="Maria Rosa Logo" />
          </div>
          <div className="logo">
            <Link to="/"><img src={Logo} alt="" /></Link>
          </div>
          <div className="header-nav">
            <Nav />
          </div>
        </div>
      </div>
    </header>
  );
}
