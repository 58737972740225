import React from "react";
import Header from "../components/header";
import "../styles/quadri.css";
import Decoration from "../assets/images/decoration.svg";
import { Helmet } from "react-helmet";

export default function Quadri() {
  return (
    <>
      <Helmet>
        <title>Maria Rosa | Quadri | Italian Restaurant</title>
        <meta
          name="description"
          content="Se vores Quadri pizza her, pizza hos Maria Rosa"
        />
      </Helmet>
      <Header />
      <div className="quadri_container">
        <div className="quadri_header">
          <h1>Quadri</h1>
          <img src={Decoration} alt="decoration" />
        </div>
        <div className="quadri_text">
          <p>QUADRI (Pizza)</p>
          <p>
            Vi Valgte Navnet Quadri For Formen Og Udseendet Af Vores Pizza, Som
            Ikke Er En Normal Pizza, Men En PINSA PINSA ROMANA
          </p>
          <p>
            Det Latinske Verbum Pinsare (At Knuse, Knibe) Gav I Det Gamle Rom
            Anledning Til &quot;Pinsa&quot;, En Focaccia, Der Kan Sammenlignes
            Med Nutidens Focaccia, Som Indeholder En Blød Brødkrumme I En Enkelt
            Sprød Skal, Der Kombinerer Fortidens Visdom Med Moderne Diætetik.
            &quot;Pinsaen&quot; Fra Det Tredje Årtusinde Er Endnu Mere
            Fordøjelig End Sin Berømte Forfader. Da Spelt- Og Kamutdejen (Som
            Den Blev Tilberedt Med I Oldtiden) Skulle Erstattes Af Blødt
            Hvedemel (Rigt På Proteiner), Sojamel Og Rismel, Alt Sammen Strengt
            Ogm-Free, Ydermere Har Den Specielle Naturlige Hæveproces Givet Os
            Mulighed For At Opnå Et Produkt Af Højeste Kvalitet Og Helt Uden
            Sidestykke Fra Et Ernæringsmæssigt Og Fordøjeligt Synspunkt.
            &quot;Pinsa&quot; Er Et Beskyttet Mærke Med Præcise
            Forberedelsesregler.
          </p>
          <p>
            Vi Lægger Stor Vægt På Kvaliteten Af Ingredienserne. De Grøntsager,
            Oste Og Pølser, Vi Bruger, Kommer Fra Nøje Udvalgte Producenter, Som
            Vi Samarbejder Med Med Vores Partnere.
          </p>
        </div>
      </div>
    </>
  );
}
