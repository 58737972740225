import React from "react";
import Header from "../components/header";
import "../styles/lunch.css";
import Decoration from "../assets/images/decoration.svg";
import { Helmet } from "react-helmet";
import Footer from "../components/footer.jsx";
import LunchData from "../assets/menu/lunch.json";
import Image from "../components/image.jsx";
import PizzaData from "../assets/menu/pizza.json";

export default function Lunch() {
  return (
    <>
      <Helmet>
        <title>Maria Rosa | Lunch | Italian Restaurant</title>
        <meta
          name="description"
          content="Se menu kortet her til frokost, Italiensk mad hos Maria Rosa i Skanderborg"
        />
      </Helmet>
      <Header />
      <div className="lunch_container">
        <div className="lunch_header">
          <h1>Frokost</h1>
          <img src={Decoration} alt="decoration" />
        </div>
        <div className="lunch_content">
          {LunchData?.map((lunch, i) => (
            <div
              key={i}
              className={`lunch_data ${i % 2 === 1 ? "reverse" : ""}`}
            >
              <Image className="lunch_img" imgSrc={lunch?.imageName} />
              <div className="lunch_text">
                <h2>{lunch?.name}</h2>
                <h3>{lunch?.secondaryName}</h3>
                {lunch?.items?.map((item, i) => (
                  <div key={i} className="lunch_item">
                    <div className="lunch_item_header">
                      <h4>{item?.name}</h4>
                      <p>{item?.price},-</p>
                    </div>
                    <p>{item?.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {PizzaData?.map((pizza, i) => (
            <div
              key={i}
              className={`lunch_data ${i % 2 === 1 ? "" : "reverse"}`}
            >
              <Image className="lunch_img" imgSrc={pizza?.imageName} />
              <div className="lunch_text">
                <h2>{pizza?.name}</h2>
                <h3>{pizza?.secondaryName}</h3>
                {pizza?.items?.map((item, i) => (
                  <div key={i} className="lunch_item">
                    <div className="lunch_item_header">
                      <h4>{item?.name}</h4>
                      <p>
                        {item?.small} {item?.price_small},- {item?.big}{" "}
                        {item?.price_big},-
                      </p>
                    </div>
                    <p>{item?.description}</p>
                  </div>
                ))}
                <p className="lunch_extrainfo">{pizza?.extraInfo}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
