import React from "react";
import "../styles/bookTable.css";
import { Helmet } from "react-helmet";

import Header from "../components/header.jsx";
import Footer from "../components/footer.jsx";

export default function BookTable() {
  return (
    <>
      <Helmet>
        <title>Maria Rosa | Book Table | Italian Restaurant</title>
        <meta
          name="description"
          content="Book et bord hos Maria Rosa i Skanderborg og prøv noget dejligt mad fra Italien."
        />
      </Helmet>
      <div>
        <Header />

        <div className="bookTable">
          <div className="bookTable-container">
            <div className="bookTable-left">
              <h2>Book Table</h2>
              <a
                href="https://book.easytablebooking.com/book/?ref=mb&id=3f925&lang=us"
                target="_blank"
                rel="noreferrer"
              >
                Book Here
              </a>
            </div>
            <div className="bookTable-right">
              <h2>Contact Us</h2>
              <p>
                <span className="pri-color">Email:</span>restaurant@mariarosa.dk
              </p>
              <p>
                <span className="pri-color">Phone:</span>25 63 87 87
              </p>
              <p>
                <span className="pri-color">Address:</span>Adelgade 87, 8660
                Skanderborg
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
