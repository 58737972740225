import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Home from "./pages/home.jsx";
import BookTable from "./pages/bookTable.jsx";
import Quadri from "./pages/quadri.jsx";
import Dinner from "./pages/dinner.jsx";
import Lunch from "./pages/lunch.jsx";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Helmet>
          <title>Maria Rosa</title>
          <meta
            name="description"
            content="Hos Maria Rosa leverer vi fantastisk italiensk mad til din fornøjelse, se vores udvalg af måltider. Du kan spise på vores restaurant i Skanderborg eller tage det med hjem!"
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/dinner" element={<Dinner />}></Route>
          <Route path="/lunch" element={<Lunch />}></Route>
          <Route path="/bookTable" element={<BookTable />}></Route>
          <Route path="/quadri" element={<Quadri />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
