import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../styles/nav.css";

export default function Nav() {
  const [active, setActive] = useState(true);
  const [hamburger, setHamburger] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);

  const toggleClass = () => {
    setActive(!active);
    setHamburger(!active);
  };

  const toggleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <nav className="nav">
        <div className={active ? "hamburger-menu" : "hamburger-menu module"}>
          <div
            onClick={toggleClass}
            className={hamburger ? "hamburger" : "hamburger hamburgerClose"}
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
            <div className="line4"></div>
          </div>
          <ul className={active ? "displayNone" : "nav-ulist"}>
            <li className="nav-list">
              <HashLink className="nav-link" smooth to="/">
                Home
              </HashLink>
            </li>
            <li className="nav-list nav-link" onClick={toggleOpenMenu}>
              Menu
            </li>
            {openMenu && (
              <div className="openMenu">
                <li className="nav-list">
                  <HashLink className="nav-link" smooth to="/dinner">
                    Aften
                  </HashLink>
                </li>
                <li className="nav-list">
                  <HashLink className="nav-link" smooth to="/lunch">
                    Lunch
                  </HashLink>
                </li>
              </div>
            )}

            <li className="nav-list">
              <HashLink className="nav-link" smooth to="/quadri">
                Quadri
              </HashLink>
            </li>
            <li className="nav-list">
              <HashLink className="nav-link" smooth to="/bookTable">
                Book Bord
              </HashLink>
            </li>
            <li className="nav-list">
              <HashLink className="nav-link" smooth to="/bookTable">
                Kontakt Os
              </HashLink>
            </li>
            <li className="nav-list">
              <HashLink className="nav-link" smooth to="/#openingHours">
                Åbningstider
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>

      <Outlet />
    </>
  );
}
