import React from "react";
import "../styles/home.css";

import Nav from "../components/nav.jsx";
import OpeningHours from "../assets/openingHours.json";

import Logo1 from "../assets/images/logo_3.png";
import { Link } from "react-router-dom";
import Decoration from "../assets/images/decoration.svg";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Maria Rosa | Home | Italian Restaurant</title>
        <meta
          name="description"
          content="Italiensk mad hos Maria Rosa i Skanderborg"
        />
      </Helmet>
      <div className="home">
        <div className="home-hero">
          <div className="home-container">
            <div className="home-header">
              <div></div>
              <div>
                <img src={Logo1} alt="Maria Rosa Logo" />
              </div>
              <div className="home-nav">
                <Nav />
              </div>
            </div>
            <h1>Italiensk Restaurant</h1>
            <div className="home-hero-links-container">
              <Link className="home-hero-links" to="bookTable">
                Book Bord
              </Link>
              <Link className="home-hero-links" to="/dinner">
                Menu
              </Link>
            </div>
          </div>
        </div>

        <div className="home-section1">
          <div className="home-section1-left"></div>
          <div className="home-section1-right">
            <h2>
              Autentisk <br></br> Italiensk Mad
            </h2>
            <img src={Decoration} alt="" />
            <p>
              Hos Maria Rosa kan du få autentisk italiensk mad. Du kan få
              traditionel italiensk pasta, pizza, kød, fisk og meget mere.
            </p>
            <br />
            <p>Du kan også få frokost, aftensmad og brunch.</p>
            <br />
            <p>Du kan spise på restauranten eller bestille til afhentning.</p>
          </div>
        </div>
        <div className="home-section2"></div>
        <div className="home-section3">
          <div className="home-section3-left"></div>
          <div className="home-section3-right" id="openingHours">
            <h2>Åbningstider</h2>
            <img src={Decoration} alt="" />
            <div>
              <p>{OpeningHours.when}</p>
              {OpeningHours.openingHours.map((opening, index) => (
                <p key={index}>
                  {opening.day}:{" "}
                  {opening.info
                    ? opening.info
                    : `${opening.open} - ${opening.close} / ${opening.open2} - ${opening.close2}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
