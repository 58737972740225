import React from "react";

const Image = ({ className, imgSrc }) => {
  const divStyle = {
    flex: 1,
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "black",
  };

  return <div className={className} style={divStyle}></div>;
};

export default Image;
