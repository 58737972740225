import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";

import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div>
        <p>
          Copyright &copy; - Restaurant Maria Rosa | Adelgade 87, 8660
          Skanderborg | 25 63 87 87
        </p>
      </div>
      <div className="footer-img-container">
        <Link to="">
          <img className="footer-image" src={Facebook} alt="" />
        </Link>
        <Link to="">
          <img className="footer-image" src={Instagram} alt="" />
        </Link>
      </div>
    </div>
  );
}
